<template>
  <div
    @scroll="$emit('scroll', $event)"
    ref="container"
    :class="{
      'w-full': !pad,
      'px-5 w-full': pad,

      'mt-2': !inner,

      'flex flex-col': col && !row,
      'flex flex-row items-center': row,

      'justify-between': row && !around,
      'justify-around': row && around,

      'grow overflow-x-hidden overflow-y-scroll scrollbar-hide':
        scroll && !scrollbar,

      'grow overflow-x-hidden overflow-y-scroll': scrollbar,

      'gap-2': gap === 'sm',
      'gap-4': gap === 'base',
      'gap-6': gap === 'lg',
      'gap-8': gap === 'xl',
      'gap-16': gap === 'extra',
    }"
  >
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
    scrollbar: {
      type: Boolean,
      default: false,
    },
    pad: {
      type: Boolean,
      default: false,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    col: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    around: {
      type: Boolean,
      default: false,
    },
    gap: {
      // sm base lg
      type: String,
    },
  },
  emits: {
    scroll: (e: Event) => true,
  },
  methods: {
    getBoundingClientRect() {
      if (!this.$refs.container) {
        return
      }
      const contaier = this.$refs.container as HTMLDivElement
      return contaier.getBoundingClientRect()
    },
    setScrollTop(scrollTop: number) {
      if (!this.$refs.container) {
        return
      }
      const contaier = this.$refs.container as HTMLDivElement
      contaier.scrollTop = scrollTop
    },
    scrollTo(scrollTop: number) {
      if (!this.$refs.container) {
        return
      }
      const contaier = this.$refs.container as HTMLDivElement
      contaier.scrollTo({ top: scrollTop, behavior: 'smooth' })
    },
  },
})
</script>
